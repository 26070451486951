<template>
    <div class="login-container">
        <div class="centercontain">
            <div class="left">
                <el-image class="backimage" :src="live_pop" />
            </div>

            <div class="right">
                <div class="login-cont">
                    <div class="title">注册登录</div>
                    <div class="title-text">手机号注册登录，新用户自动注册并登录</div>
                </div>
                <el-form ref="loginForm" :rules="forgetRules" :model="loginForm" class="elform">
                    <el-form-item label="" class="elitem" prop="mobile">
                        <div class="inputcontain">
                            <el-input placeholder="请输入手机号" v-model="loginForm.mobile" type="text" class="elinput"
                                maxlength="11">
                            </el-input>
                        </div>
                    </el-form-item>
                    <el-form-item label="" class="elitem" prop="sms_code">
                        <div class="inputcontain_1">
                            <el-input placeholder="请输入验证码" v-model="loginForm.sms_code" type="text" class="elinput">
                                <template slot="append" v-if="show">
                                    <div @click="getCode">
                                        <span>获取验证码</span>
                                    </div>
                                </template>
                                <template slot="append" v-if="!show">
                                    <div @click="getCode" class="getCode">
                                        <span>{{ count + "s" }}后重发</span>
                                    </div>
                                </template>
                            </el-input>
                        </div>
                    </el-form-item>
                    <el-form-item>
                        <el-checkbox v-model="loginForm.auto" class="elcheckbox">自动登录</el-checkbox>
                    </el-form-item>
                </el-form>
                <div class="logbt">
                    <el-button class="bt" @click="registerLogin" :loading="loading">登录</el-button>
                </div>
                <!-- <div class="agreement">
                    <el-checkbox v-model="loginForm.agre">阅读并同意《用户协议》《隐私政策》</el-checkbox>
                </div> -->
            </div>
        </div>
    </div>
</template>
  
<script>
import { setToken, setIdentifierToken } from "@/utils/auth";
import { sendsmscode } from "@/api/user";
import { relogin } from "@/api/live.js";
import md5 from "js-md5";
export default {
    name: "live-login",
    components: {},
    data() {
        const codeCheck = (rule, value, callback) => {
            if (value == "") {
                callback(new Error("请输入正确的验证码!"));
            } else {
                callback();
            }
        };
        return {
            live_pop: require("@/assets/live/live_pop.png"),
            show: true,
            count: "",
            loginForm: {
                mobile: "", // 手机号格式
                auto: false,
                sms_code: "",
            },
            forgetRules: {
                mobile: [
                    {
                        required: true,
                        message: "请输入手机号码",
                        trigger: "blur",
                    },
                    {
                        min: 6,
                        max: 11,
                        message: "手机号码长度不能超过11个字符",
                        trigger: "blur",
                    },
                    {
                        pattern: /^[0-9]{6,11}$/,
                        message: "请输入正确的手机号码",
                    },
                    {
                        pattern: /^1[3|4|5|6|7|8][0-9]{9}$/,
                        message: "请输入正确的手机号码",
                    },
                ],
                sms_code: [
                    {
                        required: true,
                        message: "请输入验证码",
                        trigger: "blur",
                    },
                    {
                        required: true,
                        validator: codeCheck,
                    },
                ],
            },
            loading: false,
            sn: "",
            title: "",
            timer: null
        };
    },

    created() {
        this.sn = this.$route.query.sn
        this.title = this.$route.query.title
    },
    mounted() {
        let link = document.getElementsByTagName('link')[0]
        link.href = "https://shijian.hzau.edu.cn/portal/logo.ico"
        document.title = "直播登录"
    },
    destroyed() {
        // window.removeEventListener('storage', this.afterQRScan)
    },
    methods: {
        getCode() {
            if (this.loginForm.mobile && this.loginForm.mobile.length === 11) {
                const time = new Date().getTime();
                const params = {
                    mobile: this.loginForm.mobile,
                    sign: md5(md5(this.loginForm.mobile) + time),
                    time: time,
                };
                sendsmscode(params)
                    .then((res) => {
                        const { data } = res;
                        if (res.code === 0) {
                            const TIME_COUNT = 60;
                            if (!this.timer) {
                                this.count = TIME_COUNT;
                                this.show = false;
                                this.timer = setInterval(() => {
                                    if (this.count > 0 && this.count <= TIME_COUNT) {
                                        this.count--;
                                    } else {
                                        this.show = true;
                                        clearInterval(this.timer);
                                        this.timer = null;
                                    }
                                }, 1000);
                            }
                        } else if (res.data && res.data.sms_code) {
                            this.loginForm.sms_code = res.data.sms_code;
                        } else {
                            this.$message({
                                message: res.message,
                                type: "error",
                            });
                        }
                    })
                    .catch((error) => { });
            } else {
                this.$message({
                    message: "请输入正确的手机号码!",
                    type: "error",
                });
            }
        },
        // 注册
        registerLogin() {
            this.loading = true
            if (this.loginForm.sms_code) {
                const params = {
                    mobile: this.loginForm.mobile,
                    sms_code: this.loginForm.sms_code,
                };
                relogin(params)
                    .then((res) => {
                        console.log("res", res);
                        this.loading = false
                        if (res.code === 0) {
                            this.$message({
                                message: "恭喜您，登录成功！！！",
                                type: "success",
                            });
                            this.$router.push({
                                path:
                                    "/home/livebroadcast/courselearning?sn=" +
                                    this.sn + "&header_flag=1",
                            });
                            document.title = this.title
                            localStorage.setItem('live_login_flag', "1")
                            setToken(res.data.access_token);
                            this.$store.commit("SET_TOKEN", res.data.access_token);
                            console.log('state.token-------------', this.$store.state.token);

                            setIdentifierToken(res.data.user_identity[0]);
                            this.$store.commit("SET_Identifier", res.data.user_identity[0]); //获取用户身份信息
                            console.log('state.identifier----------', this.$store.state.identifier);
                        } else {
                            this.$message({
                                message: "短信验证码错误!",
                                type: "error",
                            });
                            // 错误后验证码置空
                            this.loginForm.sms_code = "";
                            this.codeCheck();
                        }
                    })
                    .catch((error) => { });
            } else {
                this.loading = false
                this.$message({
                    message: '请先获取验证码!',
                    type: 'error'
                })
            }
        },
    },
};
</script>
  
<style lang="scss" scoped>
.login-container {
    height: 100%;
    width: 100%;
    position: relative;
    background: url("../../assets/live/bg.jpg") 0 0 no-repeat;
    background-size: 100% 100%;
    // overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    .centercontain {
        width: 1380px;
        height: 486px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left {
            position: relative;
            width: 570px;
        }

        .right {
            width: 480px;
            height: 100%;

            .login-cont {
                font-size: 14px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #999999;
                margin-bottom: 60px;
                padding: 0px 7px;

                .title {
                    font-size: 24px;
                    font-weight: bold;
                    color: #000000;
                    margin-bottom: 20px;
                }
            }

            .elform {
                padding: 0px 7px;

                ::v-deep .el-input__inner {
                    height: 100%;
                    border-radius: 0px;
                    border: none;
                    border-bottom: 1px solid #DDDDDD;
                }

                ::v-deep .el-input-group__append {
                    border: none;
                    border-radius: 0px;
                    background-color: transparent;
                    color: #2EC37D;
                    cursor: pointer;
                }

                .elinput {
                    height: 56px;
                    width: 100%;

                }

                .elcheckbox {
                    ::v-deep .el-checkbox__input.is-checked+.el-checkbox__label {
                        color: #606266;
                    }

                    ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
                        background-color: #2EC37D;
                        border-color: #2EC37D;
                    }

                    ::v-deep .el-checkbox__input.is-focus .el-checkbox__inner,
                    ::v-deep .el-checkbox__inner:hover {
                        border-color: #2EC37D;
                    }
                }
            }

            .logbt {
                margin-bottom: 30px;

                .bt {
                    width: 100%;
                    height: 60px;
                    color: white;
                    background: #2EC37D;
                    border-radius: 30px;
                }
            }

            .agreement {
                padding: 0px 7px;
            }
        }
    }
}
</style>
  
  